<template>
  <div>
    <!-- Popup -->
    <Popup ref="popup" />

    <ApproveContract
      contract="staking contract"
      doSomething="stake your NFTs"
      once="once for each collection"
      v-if="collectionApprovedFor['staking'] !== true"
      @approve="approveNftsFor('staking')"
    />

    <ApproveContract
      contract="Graveyard contract"
      doSomething="sacrifice your NFTs"
      once="once for each collection. Warning: Sacrificed NFTs will no longer be in your possesion"
      v-if="collectionApprovedFor['graveyard'] !== true"
      @approve="approveNftsFor('graveyard')"
    />

    <ApproveContract
      contract="EXP token"
      doSomething="upgrade your NFTs"
      once="once"
      v-if="
        stakersAllowanceOfFor[('expToken', 'leveler')] <= 0 &&
        show.slice(0, 8) === 'Leveling'
      "
      @approve="approveExpTokenForLeveling"
    />

    <!-- <RpcWarning v-if="isDogechain" /> -->

    <div class="container">
      <div class="row">
        <div class="col-6">
          <h2 class="text-start">{{ currColl.fullName }}</h2>
          <div class="row">
            <div class="col-lg-4 col-sm-12 col-xs-12"></div>
          </div>
        </div>
      </div>

      <ExportCurrentPower
        v-if="show === 'Export Current Power'"
        :isDogechain="isDogechain"
      />

      <LevelingExplanations
        v-if="show === 'Leveling with Explanations'"
        :projectname="currColl.fullName"
        :expFor1LvlUpgrdTo="expFor1LvlUpgrdTo"
        :discountExpTokensX1k="discountExpTokensX1k"
        :levelToUpgradeTo="levelToUpgradeTo"
        :expTokensIncPerLevel="expTokensIncPerLevel"
        :maxLevel="maxLevel"
      />

      <div class="col-12">
        <div class="card filter-tab">
          <div class="card-body bs-0 p-0 bg-transparent">
            <div class="row sticky-top">
              <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <ul class="settings-menu" style="width: 100%">
                  <h4 style="width: 100%">
                    {{ selectedNfts.length }} of {{ stakersNfts.length }} NFTs
                    selected
                    <template
                      v-if="
                        show === 'Leveling' ||
                        show === 'Leveling with Explanations' ||
                        show === 'Graveyard'
                      "
                    >
                      -- Balance: {{ stakersBalanceE0["expToken"] }} EXP
                      <i
                        title="Refresh EXP Balance"
                        @click="refreshExpBalance"
                        class="ri-refresh-line"
                      ></i>
                    </template>
                  </h4>

                  <button @click="selectVisible" class="btn btn-success">
                    Select all
                  </button>

                  <button @click="clearSelection" class="btn btn-warning">
                    Clear selection
                  </button>

                  <template
                    v-if="
                      show === 'Leveling' ||
                      show === 'Leveling with Explanations'
                    "
                  >
                    <button
                      @click="upgradeSelectedNfts()"
                      class="btn btn-success"
                      :disabled="
                        selectedNfts.length <= 0 ||
                        stakersBalanceE0['expToken'] <= 0 ||
                        stakersBalanceE0['expToken'] < expCostToBulkUpgrade ||
                        stakersAllowanceOfFor[('expToken', 'leveler')] <= 0
                      "
                    >
                      Upgrade
                      {{ Math.min(selectedNfts.length, maxNftsPerTx) }}
                      for {{ expCostToBulkUpgrade }}
                      EXP
                    </button>

                    <template
                      v-if="
                        !isDogechain &&
                        stakersBalanceE0['expToken'] < expCostToBulkUpgrade
                      "
                    >
                      <button
                        @click="buyExpWithToken('nftc')"
                        class="btn btn-success"
                      >
                        Buy
                        {{
                          expCostToBulkUpgrade - stakersBalanceE0["expToken"]
                        }}
                        EXP tokens with
                        {{
                          Math.round(
                            ((expCostToBulkUpgrade -
                              stakersBalanceE0["expToken"]) *
                              expTokenPrice["nftc"]) /
                              10 ** 15
                          ) /
                          10 ** 3
                        }}
                        {{ tokenName["nftc"] }}
                      </button>
                    </template>
                  </template>

                  <button
                    v-if="show === 'Graveyard'"
                    @click="sacrificeSelectedNfts()"
                    class="btn btn-success"
                    :disabled="
                      selectedNfts.length <= 0 ||
                      collectionApprovedFor['graveyard'] === false
                    "
                    style="font-size: 14px"
                  >
                    Sacrifice
                    {{ Math.min(selectedNfts.length, maxNftsPerTx) }}
                    for {{ expForBulkSacrifice }}
                    EXP
                  </button>

                  <template v-if="show === 'Staking'">
                    <button
                      @click="stakeSelectedNfts"
                      class="btn btn-success"
                      :disabled="
                        selectedNfts.length <= 0 ||
                        collectionApprovedFor['staking'] === false
                      "
                      style="font-size: 14px"
                    >
                      Stake
                      {{ Math.min(selectedNfts.length, maxNftsPerTx) }}
                    </button>

                    <button
                      @click="unstakeSelectedNfts"
                      class="btn btn-warning"
                      :disabled="
                        selectedNfts.length <= 0 ||
                        collectionApprovedFor['staking'] === false
                      "
                      style="font-size: 14px"
                    >
                      Unstake
                      {{ Math.min(selectedNfts.length, maxNftsPerTx) }}
                    </button>
                  </template>

                  &nbsp;

                  <button
                    class="btn btn-primary"
                    type="button"
                    id="collection-filter"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Filter
                  </button>
                  <ul class="dropdown-menu" aria-labelledby="collection-filter">
                    <li>
                      <a
                        class="dropdown-item"
                        href="#"
                        @click="(filter = 'all'), sortFilterShowNfts()"
                        :class="filter === 'all' ? 'current' : ''"
                        >All ({{ stakersNfts.length }})</a
                      >
                    </li>
                    <li>
                      <a
                        class="dropdown-item"
                        href="#"
                        @click="(filter = 'staked'), sortFilterShowNfts()"
                        :class="filter === 'staked' ? 'current' : ''"
                        >Staked ({{ stakedCount }})</a
                      >
                    </li>
                    <li>
                      <a
                        class="dropdown-item"
                        href="#"
                        @click="(filter = 'unstaked'), sortFilterShowNfts()"
                        :class="filter === 'unstaked' ? 'current' : ''"
                        >Unstaked ({{ unstakedCount }})</a
                      >
                    </li>
                  </ul>

                  <button
                    class="btn btn-primary"
                    type="button"
                    id="collection-sorting"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Sorting
                  </button>
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="collection-sorting"
                  >
                    <li
                      v-for="_sortOpt in [
                        'Power',
                        'EXP needed to reach next level',
                        '% in level',
                        'Number ascending',
                        'Number descending',
                      ]"
                      :key="_sortOpt"
                    >
                      <a
                        class="dropdown-item"
                        href="#"
                        @click="(sorting = _sortOpt), sortFilterShowNfts()"
                        :class="sorting === _sortOpt ? 'current' : ''"
                        >{{ _sortOpt }}</a
                      >
                    </li>
                  </ul>

                  <button
                    class="btn btn-primary"
                    type="button"
                    id="view-mode"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {{ show }} View
                  </button>
                  <ul class="dropdown-menu" aria-labelledby="view-mode">
                    <li
                      v-for="_showOpt in [
                        'Staking',
                        'Leveling',
                        'Leveling with Explanations',
                        'Graveyard',
                        'Export Current Power',
                      ]"
                      :key="_showOpt"
                    >
                      <a
                        class="dropdown-item"
                        @click="show = _showOpt"
                        :class="show === _showOpt ? 'current' : ''"
                        >{{ _showOpt }} View</a
                      >
                    </li>
                  </ul>
                </ul>
              </div>
            </div>

            <br />

            <div
              class="row"
              v-if="
                loadingStakedNfts === false &&
                loadingUnstakedNfts === false &&
                stakersNfts.length > 0
              "
            >
              <div
                class="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-6"
                v-for="_tokenId in nftsToShow"
                :key="_tokenId"
              >
                <div class="card items">
                  <div class="card-body">
                    <div class="items-img position-relative">
                      <img
                        :src="nftInfo[_tokenId].image"
                        class="img-fluid rounded mb-3"
                        alt=""
                        @click="checkExpTokensForUpgrade(_tokenId, true)"
                      />
                    </div>

                    <label>
                      <h4 class="card-title">
                        <input
                          type="checkbox"
                          :value="_tokenId"
                          v-model="selectedNfts"
                          @click="checkExpTokensForUpgrade(_tokenId, true)"
                        />
                        #{{ _tokenId }}
                        <span v-if="nftInfo[_tokenId].staked">(staked)</span>
                        <span v-else>(unstaked)</span>
                      </h4>
                    </label>

                    <NftCardStaking
                      v-if="show === 'Staking'"
                      :nft="nftInfo[_tokenId]"
                      :disable="collectionApprovedFor['staking'] === false"
                      :maxLevel="maxLevel"
                      @stake="stakeSpecificNft(_tokenId)"
                      @unstake="unstakeSpecificNft(_tokenId)"
                    />

                    <NftCardGraveyard
                      v-if="show === 'Graveyard'"
                      :nft="nftInfo[_tokenId]"
                      :disable="collectionApprovedFor['graveyard'] === false"
                      :maxLevel="maxLevel"
                      @sacrifice="sacrificeSpecificNft(_tokenId)"
                    />

                    <NftCardLeveling
                      v-if="
                        show === 'Leveling' ||
                        show === 'Leveling with Explanations'
                      "
                      :nft="nftInfo[_tokenId]"
                      v-model:nftLevelAfterUpgrade="
                        nftInfo[_tokenId].nftLevelAfterUpgrade
                      "
                      v-model:expTokensForUpgrade="
                        nftInfo[_tokenId].expTokensForUpgrade
                      "
                      :noAllowanceForLeveling="
                        stakersAllowanceOfFor[('expToken', 'leveler')] <= 0
                      "
                      :stakersExpBalance="
                        stakersBalanceE0['expToken'].toNumber()
                      "
                      :maxLevel="maxLevel"
                      @calculateExpTokensForUpgradeAndSetPctTo0="
                        getExpForFullLvlUpgrade(_tokenId)
                      "
                      @checkExpTokensForUpgrade="
                        checkExpTokensForUpgrade(_tokenId, false)
                      "
                      @upgrade="
                        upgradeSpecificNft(
                          _tokenId,
                          Math.ceil(nftInfo[_tokenId].expTokensForUpgrade)
                        )
                      "
                    />
                  </div>
                </div>
              </div>

              <ShowMore
                :waitTime="500"
                @click="showMore()"
                @showMore="showMore()"
                v-if="nftsToShow.length < filteredNfts.length"
              />
            </div>
            <h1
              v-if="
                loadingStakedNfts === false &&
                loadingUnstakedNfts === false &&
                stakersNfts.length <= 0
              "
              class="text-center"
            >
              No NFTs to display
            </h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const Web3 = require("web3");
import { ethers } from "ethers";

import ShowMore from "./nft/ShowMore.vue";
import Popup from "./Popup.vue";

import ApproveContract from "./nft/ApproveContract.vue";
// import RpcWarning from "./nft/RpcWarning.vue";
import LevelingExplanations from "./nft/LevelingExplanations.vue";
import ExportCurrentPower from "./nft/ExportCurrentPower.vue";
import NftCardStaking from "./nft/NftCardStaking.vue";
import NftCardGraveyard from "./nft/NftCardGraveyard.vue";
import NftCardLeveling from "./nft/NftCardLeveling.vue";

import NftStake from "../../artifacts/contracts/NftStake.sol/NftStake.json";
import NftStaking from "../../artifacts/contracts/NftStaking.sol/NftStaking.json";
import SimpleNFT from "../../artifacts/contracts/SimpleNFT.sol/SimpleNFT.json";
import SimpleToken from "../../artifacts/contracts/SimpleToken.sol/SimpleToken.json";
import NftPower from "../../artifacts/contracts/NftPower.sol/NftPower.json";
import NftLeveler from "../../artifacts/contracts/NftLeveler.sol/NftLeveler.json";
import ExpToken from "../../artifacts/contracts/ExpToken.sol/ExpToken.json";
import ExpTokenSeller from "../../artifacts/contracts/ExpTokenSeller.sol/ExpTokenSeller.json";
import Graveyard from "../../artifacts/contracts/Graveyard.sol/Graveyard.json";

function removeItemOnce(arr, value) {
  let index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1); // 2nd parameter: Amount of elements to remove
  }
  // else {
  //   console.log("Unstaking an NFT that has not been staked before!");
  // }
  return arr;
}

// function matchesAllFilters(nft, filters) {
//   for (const [category, traits] of Object.entries(filters)) {
//     if (0 === traits.length) {
//       // console.log("No filter on " + category + " specified");
//       continue;
//     } else if (-1 === traits.indexOf(nft["traits",category])) {
//       // console.log("Specified traits for " + category + " not in nft " + nft.id +". Trait is " + nft["traits",category]);
//       return false;
//     }
//   }
//   // console.log(nft.id + " matches all filters");
//   return true;
// }

export default {
  props: {
    currColl: {
      type: Object,
      required: true,
      default: () => ({
        name: "",
        fullName: "",
        power: {},
        minpower: [],
        initialStakes: {},
        pictureLink: "",
        discountExpTokensX1k: 1000,
        expForLvl0: 1700,
      }),
    },
  },

  emits: ["refreshEvent"],

  components: {
    ShowMore,
    Popup,
    ApproveContract,
    LevelingExplanations,
    ExportCurrentPower,
    // RpcWarning,
    NftCardStaking,
    NftCardLeveling,
    NftCardGraveyard,
  },

  data() {
    return {
      rpc: null,
      isDogechain: window.netId === 2000 || window.netId === 568,

      tokenName: window.tokenName,

      // upgrades
      maxLevel: 100,
      levelToUpgradeTo: window.levelToUpgradeTo,
      expTokensIncPerLevel: window.expTokensIncPerLevel,
      expFor1LvlUpgrdTo: window.expFor1LvlUpgrdTo,
      discountExpTokensX1k: 1000,

      // graveyard
      sacrificeFactorX1k: 900, // 900 / 1000 = 90% (you get 90% of the worth of the NFT for sacrificing it)
      expForLvl0: 4000, // how much EXP is a level 0 NFT worth (multiplied by discountExpTokensX1k divided by 1000)

      maxNftsPerTx: window.maxNftsPerTx,

      show: "Leveling",

      walletAddress: "",

      stakersBalanceE0: {},
      stakersAllowanceOfFor: {},

      expCostToBulkUpgrade: 0,
      expForBulkSacrifice: 0,

      loadingUnstakedNfts: true,
      loadingStakedNfts: true,

      contract: [],

      provider: null,
      signer: null,

      stakersNfts: [],
      filteredNfts: [],
      nftsToShow: [],
      maxNftsToLoad: 50, // for infinite scroll lazy loading
      filter: "all",
      sorting: "Power",
      selectedNfts: [],
      nftInfo: {},

      stakedCount: 0,
      unstakedCount: 0,

      nftPower: [],
      nftLevel: [],

      collectionApprovedFor: {},

      expTokenPrice: [],
    };
  },

  methods: {
    showMore() {
      this.nftsToShow = this.filteredNfts.slice(
        0,
        this.nftsToShow.length + this.maxNftsToLoad
      );
    },

    showPopup(_title, _text, _timeOut) {
      this.$refs.popup.show(_title, _text, _timeOut);
    },

    logCurrentPower: async function () {
      console.log("Loading current NFT power. This may take a while...");

      await this.updatePower();

      console.log("Current NFT power: " + this.nftPower);
      console.log("Current NFT levels: " + this.nftLevel);
    },

    // helper for graveyard
    _getTotNftValWOLvl0: function (_power, _level) {
      let that = this;

      const partialPwrUpgradeX10 = 7;

      const totalExpTokens4Lvl = window.totalExpTokens4Lvl;

      let _exp4Lvl = totalExpTokens4Lvl[_level];
      let _exp4LvlPlus1 = totalExpTokens4Lvl[_level + 1];

      let _minPwrLvl = that.getMinPower(_level);
      let _minPwrLvlPlus1 = that.getMinPower(_level + 1);

      let expPartialLevel = Math.floor(
        Math.floor(
          ((_power - _minPwrLvl) * (_exp4LvlPlus1 - _exp4Lvl) * 10) /
            (_minPwrLvlPlus1 - _minPwrLvl)
        ) / partialPwrUpgradeX10
      );

      let expWOLvl0 = _exp4Lvl + expPartialLevel;

      if (_level > 100) {
        console.log("ERROR: Level > 100 found!");
        console.log(_level, _power, _minPwrLvl, _minPwrLvlPlus1);
        console.log(_exp4Lvl, _exp4LvlPlus1);
        console.log(expWOLvl0);
      }

      return expWOLvl0;
    },

    getSacrificePrice: function (_power, _level) {
      let that = this;

      let _expTotal =
        that._getTotNftValWOLvl0(_power, _level) + that.expForLvl0;

      // pay % of how many EXP it would take to reach that power
      let expPrice4Sacrifice = Math.floor(
        (_expTotal * that.sacrificeFactorX1k * that.discountExpTokensX1k) /
          1000000
      );

      return expPrice4Sacrifice;
    },

    getSacrificePriceSum: function (_nfts) {
      let that = this;

      let _sumExpValOfSacNfts = _nfts.length * that.expForLvl0;

      for (let i = 0; i < _nfts.length; ++i) {
        const _tokenId = _nfts[i];
        _sumExpValOfSacNfts += that._getTotNftValWOLvl0(
          that.nftInfo[_tokenId].power,
          that.nftInfo[_tokenId].level
        );
      }

      // pay % of how many EXP it would take to reach that power
      _sumExpValOfSacNfts = Math.floor(
        (_sumExpValOfSacNfts *
          that.sacrificeFactorX1k *
          that.discountExpTokensX1k) /
          1000000
      );

      return _sumExpValOfSacNfts;
    },

    // updateFilters: function () {
    //   that.filters = {}
    //   filters.Background = getSelectValues(document.getElementById("Background"));
    //   filters.Fur = getSelectValues(document.getElementById("Fur"));
    //   filters.Clothes = getSelectValues(document.getElementById("Clothes"));
    //   filters.Eyes = getSelectValues(document.getElementById("Eyes"));
    //   filters.Mouth = getSelectValues(document.getElementById("Mouth"));
    //   filters.Earring = getSelectValues(document.getElementById("Earring"));
    //   filters.Hat = getSelectValues(document.getElementById("Hat"));
    //   filters.Profile = getSelectValues(document.getElementById("Profile"));
    //   filters.Special = getSelectValues(document.getElementById("Special"));
    //   filters.trait_count = getSelectValues(document.getElementById("trait_count"));

    //   for (let nft of that.sts) {
    //     if (false == matchesAllFilters(nft, filters)) continue;
    //   }
    // },

    refreshExpBalance: async function () {
      this.showPopup("Updating EXP Balance", "", 0);
      await this.getBalance("expToken");
      this.showPopup("EXP Balance Updated", "", 3000);
    },

    buyExpWithToken: async function (_tokenName) {
      let that = this;

      let _expTokensToBuy =
        that.expCostToBulkUpgrade - that.stakersBalanceE0["expToken"];

      let expTokensInt = Math.ceil(_expTokensToBuy);
      if (expTokensInt !== _expTokensToBuy) {
        _expTokensToBuy = expTokensInt;
        return false;
      }

      if (_expTokensToBuy <= 0) {
        that.showPopup("No EXP tokens to buy?", "", 3000);
        return false;
      }

      console.log(_expTokensToBuy);

      await this.contract["expSeller"]
        .getExpPriceInTokensAndEthAndPaused([window.address["nftc"]])
        .then((pricesE0) => {
          this.expTokenPrice["nftc"] = ethers.BigNumber.from(pricesE0[0]);

          this.expTokenSellerActive = pricesE0[2].eq(0) ? true : false;
          if (!this.expTokenSellerActive) {
            this.showPopup(
              "Buying EXP Tokens is currently not possible. Please try again later!",
              "",
              3000
            );
            return false;
          }
        });

      let stakersAllowance = ethers.BigNumber.from(
        await that.contract["nftc"].allowance(
          that.walletAddress,
          window.address["expSeller"]
        )
      );

      let stakersBalance = ethers.BigNumber.from(
        await that.contract["nftc"].balanceOf(this.walletAddress)
      );

      if (_tokenName !== "nftc") {
        that.showPopup(
          _tokenName + " purchases are not possible right now.",
          " Please try another payment method.",
          3000
        );
        return false;
      }

      let tokenValue = ethers.BigNumber.from(_expTokensToBuy).mul(
        that.expTokenPrice["nftc"]
      );
      console.log(tokenValue);
      console.log(that.expTokenPrice["nftc"]);

      if (tokenValue.gt(stakersBalance)) {
        that.showPopup("Not enough " + _tokenName, "", 3000);
        return false;
      }

      if (tokenValue.lte(0)) {
        this.showPopup(
          _tokenName + " payments are currently not accepted",
          "",
          3000
        );
        return false;
      }

      if (tokenValue.gt(stakersAllowance)) {
        const _msg = "Approving " + _tokenName + " first...";

        await this.handleTx(
          _msg,
          that.contract["nftc"].approve(
            window.address["expSeller"],
            ethers.constants.MaxUint256,
            {
              gasLimit: 65000, // 60k is probably fine, too
              gasPrice: window.minGasCostE0,
            }
          )
        );

        stakersAllowance = await that.contract["nftc"].allowance(
          that.walletAddress,
          window.address["expSeller"]
        );
        if (tokenValue.gt(stakersAllowance)) {
          that.showPopup(
            "Approving " + _tokenName + " failed. Try again later...",
            "",
            3000
          );
          return false;
        }
      }

      const _msg = "Buying " + _expTokensToBuy + " EXP tokens";

      await this.handleTx(
        _msg,
        that.contract["expSeller"].buyExpWithTokens(
          _expTokensToBuy,
          window.address["nftc"],
          tokenValue,
          {
            gasLimit: 120000,
            gasPrice: window.minGasCostE0,
          }
        )
      );
      // don't call load() -- would reset the updates that the user configured
      // only reload the EXP token balance
      that.getBalance("expToken");
    },

    selectNft: function (_tokenId) {
      let that = this;
      if (that.selectedNfts.indexOf(_tokenId) < 0) {
        that.selectedNfts.push(_tokenId);
      } else {
        removeItemOnce(that.selectedNfts, _tokenId);
      }
      that.updateExpForBulkTx();
    },

    calculateExpTokensForUpgrade: function (_tokenId) {
      let that = this;

      console.log("---START calculating necessary EXP tokens---");

      that.nftInfo[_tokenId].nftLevelAfterUpgrade = parseInt(
        that.nftInfo[_tokenId].nftLevelAfterUpgrade
      );
      // console.log("nft");
      // console.log(nft.level, nft.nftLevelAfterUpgrade, nft.expTokensUntilNextLevel, this.expFor1LvlUpgrdTo[nft.nftLevelAfterUpgrade + 1], this.expFor1LvlUpgrdTo[nft.level + 1]);
      let _expTokens = 0;
      let _expTokensCorrection = 0;

      let _level = that.nftInfo[_tokenId].level;
      // BEFORE: nft is in level, should be upgraded to nftLevelAfterUpgrade - 1 (last upgrade is handled separately)
      for (
        ;
        _level < that.nftInfo[_tokenId].nftLevelAfterUpgrade - 1;
        _level++
      ) {
        console.log("upgrade from level " + _level + " to 1 higher level");
        _expTokens += Math.floor(
          (this.expFor1LvlUpgrdTo[_level + 1] * that.discountExpTokensX1k) /
            1000
        ); // cost to upgrade to next level (=== level +1)
      }
      // nft has been upgraded to level === nft.nftLevelAfterUpgrade - 1 if at least 1 full level upgrade is required
      // or to level ===  nft.nftLevelAfterUpgrade if no full level upgrade is required
      console.log("Level after full level upgrades: ", _level);

      // EXP for last upgrade
      let minpwrAfterFullUpgrades = that.getMinPower(_level);
      let power =
        that.nftInfo[_tokenId].power -
        that.nftInfo[_tokenId].minpwr +
        minpwrAfterFullUpgrades;

      console.log(
        "_expTokens",
        _expTokens,
        "power",
        power,
        "nft.minpwr",
        that.nftInfo[_tokenId].minpwr,
        "minpwrAfterFullUpgrades",
        minpwrAfterFullUpgrades
      );

      if (
        _level < that.nftInfo[_tokenId].nftLevelAfterUpgrade &&
        _level < that.maxLevel
      ) {
        // partial level upgrade to nft.nftLevelAfterUpgrade
        let minpwrNextLevel = that.getMinPower(_level + 1);
        _expTokens += Math.max(
          1,
          Math.ceil(
            (((that.expFor1LvlUpgrdTo[
              that.nftInfo[_tokenId].nftLevelAfterUpgrade
            ] *
              that.discountExpTokensX1k) /
              1000) *
              (minpwrNextLevel -
                (10 / 7) * power +
                (3 / 7) * minpwrAfterFullUpgrades)) /
              (minpwrNextLevel - minpwrAfterFullUpgrades)
          )
        );
        _level++;
      }
      if (_level < that.maxLevel) {
        let result = this.upgrade1NftGasSavings(
          _expTokens,
          _tokenId,
          that.nftInfo[_tokenId].power,
          that.nftInfo[_tokenId].level
        );
        console.log(result);
        _expTokens = result.expTokensConsumed;
        let newPwr = result.newPwrX10k;
        _level = result.newLvl;

        let minpwrCurrLevel = that.getMinPower(_level);
        let minpwrNextLevel = that.getMinPower(_level + 1);

        let newPct = Math.min(
          10000,
          Math.round(
            (((newPwr - minpwrCurrLevel) /
              (minpwrNextLevel - minpwrCurrLevel)) *
              10000) /
              0.7
          )
        );
        _expTokensCorrection = Math.ceil(
          ((Number(that.nftInfo[_tokenId].pctInLevelX100AfterUpgrade) -
            newPct) *
            that.expFor1LvlUpgrdTo[_level + 1] *
            that.discountExpTokensX1k) /
            1000 /
            10000
        );
        if (_expTokensCorrection < 0) {
          let resultTestCorrection = this.upgrade1NftGasSavings(
            _expTokens - 1,
            _tokenId,
            that.nftInfo[_tokenId].power,
            that.nftInfo[_tokenId].level
          );
          if (
            resultTestCorrection.newLvl <
            that.nftInfo[_tokenId].nftLevelAfterUpgrade
          ) {
            console.log("_expTokensCorrection set to 0");
            _expTokensCorrection = 0;
          }
        }
        console.log(
          "newPct",
          newPct,
          "nft.pctInLevelX100AfterUpgrade",
          that.nftInfo[_tokenId].pctInLevelX100AfterUpgrade
        );
      }
      console.log("upgrade should use", _expTokens, "+", _expTokensCorrection);

      // if (nft.nftLevelAfterUpgrade > nft.level + 1) { // multiple levels to upgrade

      // } else if (nft.nftLevelAfterUpgrade === nft.level + 1) { // max 1 level to upgrade
      //   _expTokens += nft.expTokensUntilNextLevel;
      //   if (nft.nftLevelAfterUpgrade < that.maxLevel) {
      //     _expTokens += Math.max(1, Math.round((Number(nft.pctInLevelX100AfterUpgrade) - nft.pctInLevelX100) * this.expFor1LvlUpgrdTo[nft.nftLevelAfterUpgrade + 1] / 10000 * this.discountExpTokensX1k / 1000));
      //   }
      // } else if (nft.nftLevelAfterUpgrade === nft.level) {
      //   _expTokens += Math.max(1, Math.round((Number(nft.pctInLevelX100AfterUpgrade) - nft.pctInLevelX100) * this.expFor1LvlUpgrdTo[nft.nftLevelAfterUpgrade + 1] / 10000 * this.discountExpTokensX1k / 1000));
      // }

      if (_level !== that.nftInfo[_tokenId].nftLevelAfterUpgrade) {
        console.log(
          "EXP token calculation failed! This is a bug and shouldn't happen!"
        );
        return false;
      }

      _expTokens = Math.max(1, _expTokens + _expTokensCorrection);

      let keepGoing = true;
      do {
        console.log("checking level");
        let result = this.upgrade1NftGasSavings(
          _expTokens,
          _tokenId,
          that.nftInfo[_tokenId].power,
          that.nftInfo[_tokenId].level
        );
        let testlevel = result.newLvl;
        if (testlevel < that.nftInfo[_tokenId].nftLevelAfterUpgrade) {
          console.log("testlevel is too low: ", _expTokens);
          _expTokens += 1;
        } else if (
          testlevel > that.nftInfo[_tokenId].nftLevelAfterUpgrade &&
          _expTokens > 1
        ) {
          console.log("testlevel is too high: ", _expTokens);
          _expTokens -= 1;
        } else {
          keepGoing = false;
        }
      } while (keepGoing);

      this.nftInfo[_tokenId].expTokensForUpgrade = Math.max(1, _expTokens);

      this.calculatePowerIncreaseSolidityV2(
        this.nftInfo[_tokenId].expTokensForUpgrade,
        _tokenId
      );

      this.updateExpForBulkTx();
    },

    set_A2Mod_between_Min_and_Max: function (A2Mod, _Min, _Max) {
      if (A2Mod < _Min) {
        return _Min;
      } else if (A2Mod > _Max) {
        return _Max;
      } else {
        return A2Mod;
      }
    },

    getExpForFullLvlUpgrade: function (_tokenId) {
      const _verbose = false;

      const that = this;

      // make nftLevelAfterUpgrade integer
      that.nftInfo[_tokenId].nftLevelAfterUpgrade = parseInt(
        that.nftInfo[_tokenId].nftLevelAfterUpgrade
      );

      that.nftInfo[_tokenId].nftLevelAfterUpgrade =
        that.set_A2Mod_between_Min_and_Max(
          that.nftInfo[_tokenId].nftLevelAfterUpgrade,
          that.nftInfo[_tokenId].level,
          that.maxLevel
        );

      // handle the case in which nftLevelAfterUpgrade is <= level
      if (
        that.nftInfo[_tokenId].nftLevelAfterUpgrade <=
        that.nftInfo[_tokenId].level
      ) {
        that.nftInfo[_tokenId].expTokensForUpgrade = 1;
        that.calculatePowerIncreaseSolidityV2(
          that.nftInfo[_tokenId].expTokensForUpgrade,
          _tokenId
        );

        that.updateExpForBulkTx();
        return;
      }

      // nftLevelAfterUpgrade > level
      let _expTokens = that.nftInfo[_tokenId].expTokensUntilNextLevel;
      for (
        let _level = that.nftInfo[_tokenId].level + 1;
        _level < that.nftInfo[_tokenId].nftLevelAfterUpgrade;
        ++_level
      ) {
        if (_verbose) {
          console.log("upgrade from level " + _level + " to " + _level + 1);
        }

        _expTokens += Math.floor(
          (that.expFor1LvlUpgrdTo[_level + 1] * that.discountExpTokensX1k) /
            1000
        ); // cost to upgrade from level to level +1
      }

      let _res1 = this.upgrade1NftGasSavings(
        _expTokens,
        _tokenId,
        that.nftInfo[_tokenId].power,
        that.nftInfo[_tokenId].level
      );

      let testlevel = _res1.newLvl;
      // let testPctX100 = this.getPctX100InLvl(
      //   result.newPwrX10k,
      //   result.newLvl
      // );
      let _exp4NextLvlUpgrd = Math.floor(
        (that.expFor1LvlUpgrdTo[testlevel + 1] * that.discountExpTokensX1k) /
          1000
      );
      let _expTokensInFinalLvl = Math.round(
        ((_res1.newPwrX10k - that.getMinPower(testlevel)) *
          100 *
          _exp4NextLvlUpgrd) /
          ((that.getMinPower(testlevel + 1) - that.getMinPower(testlevel)) * 70)
      ); // note: round gives correct results, floor does not in rare cases

      if (_expTokensInFinalLvl !== 0) {
        _expTokens -= _expTokensInFinalLvl;

        let keepGoing = true;
        do {
          console.log("checking level");
          let _res2 = this.upgrade1NftGasSavings(
            _expTokens,
            _tokenId,
            that.nftInfo[_tokenId].power,
            that.nftInfo[_tokenId].level
          );
          let _tstlvl = _res2.newLvl;
          // let testPctX100 = this.getPctX100InLvl(_res2.newPwrX10k, _res2.newLvl);

          if (_tstlvl < that.nftInfo[_tokenId].nftLevelAfterUpgrade) {
            console.log("testlevel is too low: ", _expTokens);
            _expTokens += 1;
          } else {
            keepGoing = false;
          }
        } while (keepGoing);
      }

      that.nftInfo[_tokenId].expTokensForUpgrade = Math.max(1, _expTokens);

      that.calculatePowerIncreaseSolidityV2(
        that.nftInfo[_tokenId].expTokensForUpgrade,
        _tokenId
      );

      that.updateExpForBulkTx();
    },

    checkExpTokensForUpgrade: function (_tokenId, _selectDeselect) {
      let that = this;

      that.nftInfo[_tokenId].expTokensForUpgrade = parseInt(
        that.nftInfo[_tokenId].expTokensForUpgrade
      );

      if (_selectDeselect === true) {
        if (that.selectedNfts.indexOf(_tokenId) < 0) {
          that.selectedNfts.push(_tokenId);
        } else {
          removeItemOnce(that.selectedNfts, _tokenId);
        }
      }

      that.calculatePowerIncreaseSolidityV2(
        that.nftInfo[_tokenId].expTokensForUpgrade,
        _tokenId
      );
      if (that.nftInfo[_tokenId].nftLevelAfterUpgrade >= that.maxLevel) {
        that.getExpForFullLvlUpgrade(_tokenId);
        return;
      }
      that.updateExpForBulkTx();
    },

    getPowerVue: function (_tokenId) {
      return this.nftInfo[_tokenId].power;
    },

    getMinPower: function (level) {
      if (level > this.maxLevel + 1) {
        level = this.maxLevel + 1;
      }
      return this.currColl.minpower[level];
    },

    getExpTokensIncPerLevel: function (level) {
      let uiIdx = 0;
      while (level >= this.levelToUpgradeTo[uiIdx]) {
        uiIdx++;
      }
      return this.expTokensIncPerLevel[uiIdx];
    },

    getHighestLevelToUpgradeToBeforePriceIncrease: function (level) {
      let uiIdx = 0;
      while (level >= this.levelToUpgradeTo[uiIdx]) {
        uiIdx++;
      }
      return this.levelToUpgradeTo[uiIdx];
    },

    approveExpTokenForLeveling: async function () {
      const _msg = "Approving EXP token";

      await this.handleTx(
        _msg,
        this.contract["expToken"].approve(
          window.address["leveler"],
          ethers.constants.MaxUint256,
          {
            gasLimit: 65000, // 60k is probably fine, too
            gasPrice: window.minGasCostE0,
          }
        )
      );

      this.getAllowanceOfFor("expToken", "leveler");
    },

    approveNftsFor: async function (_contractName) {
      const _msg = `Approving NFTs for ${_contractName}`;

      await this.handleTx(
        _msg,
        this.contract["nft"].setApprovalForAll(
          window.address[_contractName],
          true,
          {
            gasLimit: 60000,
            gasPrice: window.minGasCostE0,
          }
        )
      );

      this.load();
    },

    handleTx: async function (_msg, _fx) {
      this.showPopup(_msg, "Please confirm the transaction", 0);

      try {
        let tx = await _fx;
        this.showPopup(_msg, "Waiting for transaction to finish", 0);
        await tx.wait();
        this.showPopup(_msg, "was successful", 1000);
      } catch {
        this.showPopup(_msg, "finished", 1000);
      }
    },

    stakeSpecificNft: async function (_tokenId) {
      const _msg = `Staking #${_tokenId}`;

      let _options = {
        value: ethers.utils.parseUnits("2", 14),
        gasLimit: 500000,
        gasPrice: window.minGasCostE0,
      };
      if (this.isDogechain) {
        _options = {
          value: ethers.utils.parseUnits("2", 16),
          gasLimit: 240000,
          gasPrice: window.minGasCostE0,
        };
      }

      await this.handleTx(
        _msg,
        this.contract["staking"].stake(
          this.currColl.nftCA,
          [_tokenId],
          _options
        )
      );

      this.clearSelection();
      this.$emit("refreshEvent");
    },

    unstakeSpecificNft: async function (_tokenId) {
      const _msg = `Unstaking #${_tokenId}`;

      // use new CA for kimonOld
      const _nftCA =
        this.currColl.nftCA === window.address["kimonOld"]
          ? window.address["kimon"]
          : this.currColl.nftCA;

      let _options = {
        value: ethers.utils.parseUnits("6", 14),
        gasLimit: 500000,
        gasPrice: window.minGasCostE0,
      };
      if (this.isDogechain) {
        _options = {
          value: ethers.utils.parseUnits("6", 16),
          gasLimit: 250000,
          gasPrice: window.minGasCostE0,
        };
      }
      await this.handleTx(
        _msg,
        this.contract["staking"].unstake(_nftCA, [_tokenId], _options)
      );

      this.clearSelection();
      this.$emit("refreshEvent");
    },

    selectVisible: function () {
      this.selectedNfts = [];
      for (let _tokenId of this.filteredNfts) {
        if (this.selectedNfts.indexOf(_tokenId) < 0) {
          this.selectedNfts.push(_tokenId);
        }
      }

      this.updateExpForBulkTx();
    },

    clearSelection: function () {
      this.selectedNfts = [];
      this.updateExpForBulkTx();
    },

    stakeSelectedNfts: async function () {
      let IDs = [];

      let _copySelectedNfts = this.selectedNfts.slice();

      for (let _tokenId of _copySelectedNfts) {
        if (
          this.nftInfo[_tokenId].staked === false &&
          IDs.length < this.maxNftsPerTx
        ) {
          // console.log(this.selectedNfts[i], this.stakersNfts[findIdx]);
          IDs.push(_tokenId);
        } else {
          removeItemOnce(this.selectedNfts, _tokenId);
        }
      }

      this.selectedNfts = IDs.slice();

      if (IDs.length === 0) {
        this.showPopup("No selected NFT matches all criteria.", "", 3000);
        return false;
      }
      const _nfts = IDs.length > 1 ? "NFTs" : "NFT";
      const _msg = `Staking ${IDs.length} ${_nfts}.`;

      const _cost = String(2 * IDs.length);
      let _options = {
        value: ethers.utils.parseUnits(_cost, 14),
        gasLimit: Math.min(500000 + 400000 * IDs.length, 9999999),
        gasPrice: window.minGasCostE0,
      };
      if (this.isDogechain) {
        _options = {
          value: ethers.utils.parseUnits(_cost, 16),
          gasLimit: Math.min(
            240000 + 110000 * IDs.length - 15000 * Math.floor(IDs.length / 5),
            window.maxGasPerTx
          ),
          gasPrice: window.minGasCostE0,
        };
      }

      await this.handleTx(
        _msg,
        this.contract["staking"].stake(this.currColl.nftCA, IDs, _options)
      );

      this.clearSelection();
      this.$emit("refreshEvent");
    },

    unstakeSelectedNfts: async function () {
      let IDs = [];

      let _copySelectedNfts = this.selectedNfts.slice();

      for (let _tokenId of _copySelectedNfts) {
        if (
          this.nftInfo[_tokenId].staked === true &&
          IDs.length < this.maxNftsPerTx
        ) {
          // console.log(this.selectedNfts[i], this.filteredNfts[findIdx]);
          IDs.push(_tokenId);
        } else {
          removeItemOnce(this.selectedNfts, _tokenId);
        }
      }

      this.selectedNfts = IDs.slice();

      if (IDs.length === 0) {
        this.showPopup("No selected NFT matches all criteria.", 3000);
        return false;
      }

      const _nfts = IDs.length > 1 ? "NFTs" : "NFT";
      const _msg = `Unstaking ${IDs.length} ${_nfts}.`;
      this.showPopup(_msg, "", 0);

      // use new CA for kimonOld
      const _nftCA =
        this.currColl.nftCA === window.address["kimonOld"]
          ? window.address["kimon"]
          : this.currColl.nftCA;

      const _cost = String(6 * IDs.length);
      let _options = {
        value: ethers.utils.parseUnits(_cost, 14),
        gasLimit: Math.min(500000 + 400000 * IDs.length, 9999999),
        gasPrice: window.minGasCostE0,
      };
      if (this.isDogechain) {
        _options = {
          value: ethers.utils.parseUnits(_cost, 16),
          gasLimit: Math.min(250000 + 150000 * IDs.length, 9999999),
          gasPrice: window.minGasCostE0,
        };
      }

      await this.handleTx(
        _msg,
        this.contract["staking"].unstake(_nftCA, IDs, _options)
      );

      this.clearSelection();
      this.$emit("refreshEvent");
    },

    updateExpForBulkTx: function () {
      let nftsFound = 0;
      let _expCostToBulkUpgrade = 0;
      let _expForBulkSacrifice = 0;

      for (let idx = 0; idx < this.selectedNfts.length; idx++) {
        let _tokenId = this.selectedNfts[idx];
        let _cost = this.nftInfo[_tokenId].expTokensForUpgrade;
        let _sacrifice = this.nftInfo[_tokenId].sacrificePrice;
        if (nftsFound < this.maxNftsPerTx) {
          if (this.show === "Leveling" && _cost === 0) {
            continue;
          }
          if (this.show === "Graveyard" && _sacrifice === 0) {
            continue;
          }
          _expCostToBulkUpgrade += _cost;
          _expForBulkSacrifice += _sacrifice;
          nftsFound++;
        }
      }

      if (nftsFound >= this.maxNftsPerTx) {
        this.showPopup(
          `Note:`,
          `Max ${this.maxNftsPerTx} NFTs per transaction`,
          3000
        );
      }

      this.expCostToBulkUpgrade = _expCostToBulkUpgrade;
      this.expForBulkSacrifice = _expForBulkSacrifice;
    },

    // setRpcIndex: function (param) {

    //   this.rpcIndex = param;
    //   // window.rpcIndex = param; // doesn't work
    //   console.log(this.rpcIndex);
    //   this.load();
    // },

    updatePower: async function () {
      // // don't read events from blockchain
      // this.nftPower = this.currColl.power["power"].slice();
      // this.nftLevel = this.currColl.power["level"].slice();
      // return true;

      // read events from blockchain

      let that = this;

      return new Promise((resolve, reject) => {
        // load stored power and level from json file
        that.nftPower = that.currColl.power["power"].slice();
        that.nftLevel = that.currColl.power["level"].slice();

        // loading power updates that came later
        console.log("nextBlock: " + that.currColl.power.nextBlockToCheck);
        const optionsPower = {
          fromBlock: that.currColl.power.nextBlockToCheck, //Number || "earliest" || "pending" || "latest"
          toBlock: "latest",
        };

        try {
          let web3HttpReadPower = new Web3(window.rpc);
          const levelContractHttp = new web3HttpReadPower.eth.Contract(
            NftLeveler.abi,
            window.address["leveler"]
          );

          console.log("Getting past events for power upgrades");
          levelContractHttp
            .getPastEvents("powerUpgraded", optionsPower)
            .then(function (events) {
              if (0 >= events.length) {
                console.log("No events");
                return true;
              }
              // that.currColl.power.nextBlockToCheck = Math.max(that.currColl.power.nextBlockToCheck, (events[events.length - 1].blockNumber + 1));

              console.log(events.length + " events found for power upgrades");

              for (let item of events) {
                // console.log(item);
                let ca = item.returnValues.ca;
                let id = parseInt(item.returnValues.tokenId);
                let newPwrX10k = parseInt(item.returnValues.newPwrX10k);

                // current collection power upgrade
                if (ca == that.currColl.nftCA) {
                  that.nftPower[id - 1] = newPwrX10k;
                  console.log(
                    id +
                      " upgraded to " +
                      newPwrX10k +
                      " == " +
                      that.nftPower[id - 1] +
                      " in " +
                      item.blockNumber
                  );

                  let level = that.nftLevel[id - 1]; // start with old level -- assumes we can only increase power

                  let minpwr;
                  do {
                    minpwr = that.getMinPower(level);
                    level++;
                  } while (minpwr <= newPwrX10k);

                  that.nftLevel[id - 1] = level - 2;
                }
              }
            })
            .then(() => {
              console.log("returning Power");
              resolve();
            });
        } catch (e) {
          console.log(e);
          reject();
        }
      });
    },

    sacrificeSpecificNft: async function (_tokenId) {
      let that = this;

      let _exp4Sacrifice = that.nftInfo[_tokenId].sacrificePrice;
      if (_exp4Sacrifice === 0) {
        this.showPopup("Can't sacrifice for 0 EXP", "", 3000);
        return false;
      }

      // // check owner
      // let _nftOwner = await that.contract["nft"].ownerOf(_tokenId);
      // if (_nftOwner !== that.walletAddress) {
      //   if (_nftOwner !== window.address["staking"]) {
      //     that.showPopup(_tokenId + " is not in your wallet", '', 3000);
      //     return false;
      //   }
      // }

      const _msg = "Sacrificing 1 NFT for " + _exp4Sacrifice + " EXP";

      console.log("Balance old= " + that.stakersBalanceE0["expToken"]);
      let balanceNew =
        Number(that.stakersBalanceE0["expToken"]) + _exp4Sacrifice;
      console.log("Balance new= " + balanceNew);

      if (that.nftInfo[_tokenId].staked) {
        let gasLimit2Use = 480000;

        await this.handleTx(
          _msg,
          that.contract["graveyard"].sacrifice1Staked(
            that.currColl.nftCA,
            _tokenId,
            _exp4Sacrifice,
            {
              gasLimit: gasLimit2Use,
              gasPrice: window.minGasCostE0,
            }
          )
        );
      } else {
        let gasLimit2Use = 260000;

        await this.handleTx(
          _msg,
          that.contract["graveyard"].sacrifice1Unstaked(
            that.currColl.nftCA,
            _tokenId,
            _exp4Sacrifice,
            {
              gasLimit: gasLimit2Use,
              gasPrice: window.minGasCostE0,
            }
          )
        );
      }
      that.load();
    },

    upgradeSpecificNft: async function (_tokenId, _expTokens) {
      let that = this;

      if (_expTokens === 0) {
        that.showPopup("Can't upgrade with 0 EXP", "", 3000);
        return false;
      }

      if (
        that.currColl.name === "kimonOld" &&
        that.nftInfo[_tokenId].staked === false
      ) {
        that.showPopup(
          "Kimon NFTs from the old contract",
          "can only be upgraded when they are staked.",
          3000
        );
        removeItemOnce(that.selectedNfts, _tokenId);
        return false;
      }

      // that.calculatePowerIncreaseSolidityV2(_expTokens, _tokenId);

      // checking EXP token
      if (_expTokens > that.stakersAllowanceOfFor[("expToken", "leveler")]) {
        that.showPopup(
          "Please increase your EXP token allowance first",
          "",
          3000
        );
        return false;
      } else if (_expTokens > that.stakersBalanceE0["expToken"]) {
        that.showPopup("EXP token balance is too low", "", 3000);
        return false;
      }

      let level = that.nftInfo[_tokenId].level;
      if (level >= that.maxLevel) {
        that.showPopup(
          "Cannot upgrade " + _tokenId + ", because it's already at max. level",
          "",
          3000
        );

        return false;
      }

      // // check owner
      // let _nftOwner = await that.contract["nft"].ownerOf(_tokenId);
      // if (_nftOwner !== that.walletAddress) {
      //   if (_nftOwner !== window.address["staking"]) {
      //     that.showPopup(_tokenId + " is not in your wallet", '', 3000);
      //
      //     return false;
      //   }
      // }

      const _msg = "Upgrading #" + _tokenId + " with " + _expTokens + " EXP";

      console.log("Power old= " + that.nftInfo[_tokenId].power);
      console.log("Level old= " + level);
      console.log("Balance old= " + that.stakersBalanceE0["expToken"]);
      let balanceNew = that.stakersBalanceE0["expToken"] - _expTokens;
      console.log("Balance new= " + balanceNew);

      let gasLimit2Use =
        that.nftInfo[_tokenId].nftLevelAfterUpgrade -
          that.nftInfo[_tokenId].level <
        2
          ? 330000
          : 350000;

      await this.handleTx(
        _msg,
        that.contract["leveler"].upgrade1Nft(
          that.currColl.nftCA,
          _tokenId,
          _expTokens,
          {
            gasLimit: gasLimit2Use,
            gasPrice: window.minGasCostE0,
          }
        )
      );

      if (that.nftInfo[_tokenId].staked) {
        this.clearSelection();
        this.$emit("refreshEvent");
      } else {
        that.load();
      }
    },

    sacrificeSelectedNfts: async function () {
      let that = this;

      if (that.selectedNfts.length < 1) {
        that.showPopup("No NFTs selected", "", 3000);
        return false;
      } else if (that.selectedNfts.length === 1) {
        let _tokenId = that.selectedNfts[0];
        that.sacrificeSpecificNft(_tokenId);
        return true;
      }
      // else if (that.selectedNfts.length > 1) {
      // go through selected NFTs and pick first maxNftsPerTx which qualify for an upgrade
      let nftsToSacrificeArrayStaked = [];
      let expTokensForSacrificeArrayStaked = [];
      let nftsToSacrificeArrayUnstaked = [];
      let expTokensForSacrificeArrayUnstaked = [];

      that.showPopup(
        "Double checking ownership and level of selected NFTs",
        "",
        3000
      );

      // making a copy makes it easier to use removeItemOnce in for loop
      let _copySelectedNfts = this.selectedNfts.slice();

      let gasLimit2Use = 0;
      let _feedback = "";
      for (let _tokenId of _copySelectedNfts) {
        // check sacrifice price
        if (that.nftInfo[_tokenId].sacrificePrice === 0) {
          _feedback += "Skipping " + _tokenId + " (0 EXP sacrifice) -- ";
          removeItemOnce(this.selectedNfts, _tokenId);
          continue;
        }

        if (
          nftsToSacrificeArrayStaked.length +
            nftsToSacrificeArrayUnstaked.length >=
          that.maxNftsPerTx
        ) {
          removeItemOnce(this.selectedNfts, _tokenId);
          continue;
        }

        // // check owner
        // let _nftOwner = await this.contract["nft"].ownerOf(_tokenId);
        // if (_nftOwner !== that.walletAddress) {
        //   if (_nftOwner !== window.address["staking"]) {
        //     _feedback +=
        //       " -- Skipping " + _tokenId + " (not in your wallet)";
        //
        //     removeItemOnce(this.selectedNfts, _tokenId);
        //     continue;
        //   }
        // }

        if (that.nftInfo[_tokenId].staked === true) {
          console.log("Adding staked " + _tokenId);
          nftsToSacrificeArrayStaked.push(_tokenId);
          expTokensForSacrificeArrayStaked.push(
            that.nftInfo[_tokenId].sacrificePrice
          );
        } else {
          console.log("Adding unstaked " + _tokenId);
          nftsToSacrificeArrayUnstaked.push(_tokenId);
          expTokensForSacrificeArrayUnstaked.push(
            that.nftInfo[_tokenId].sacrificePrice
          );
        }

        if (that.nftInfo[_tokenId].staked === true) {
          if (nftsToSacrificeArrayStaked.length < 1) {
            gasLimit2Use += 460000;
          } else {
            gasLimit2Use += 400000;
          }
        } else {
          if (nftsToSacrificeArrayStaked.length < 1) {
            gasLimit2Use += 330000;
          } else {
            gasLimit2Use += 280000;
          }
        }
      }

      let totalNrOfNfts =
        nftsToSacrificeArrayStaked.length + nftsToSacrificeArrayUnstaked.length;

      if (totalNrOfNfts === 1) {
        let _tokenId = that.selectedNfts[0];
        that.sacrificeSpecificNft(_tokenId);
        return true;
      }

      _feedback += totalNrOfNfts + " NFTs passed all checks";

      let sumOfSacrificeStaked = that.getSacrificePriceSum(
        nftsToSacrificeArrayStaked
      );
      let sumOfSacrificeUnstaked = that.getSacrificePriceSum(
        nftsToSacrificeArrayUnstaked
      );
      let sumOfSacrificeTotal = sumOfSacrificeStaked + sumOfSacrificeUnstaked;

      if (totalNrOfNfts === 0) {
        that.showPopup("", _feedback, 5000);
        return false;
      } else {
        _feedback +=
          " -- Sacrificing NFTs [" +
          [...nftsToSacrificeArrayStaked, ...nftsToSacrificeArrayUnstaked] +
          "] for " +
          sumOfSacrificeTotal +
          " EXP";
        that.showPopup("", _feedback, 0);
      }

      console.log("Balance old=" + that.stakersBalanceE0["expToken"]);
      let _expBalanceAfterUpgrade =
        that.stakersBalanceE0["expToken"] + sumOfSacrificeTotal;
      console.log("Balance after bulk sacrifice: ", _expBalanceAfterUpgrade);

      if (nftsToSacrificeArrayStaked.length <= 0) {
        console.log("Sacrificing only unstaked");
        let tx = await that.contract["graveyard"].sacrificeUnstaked(
          that.currColl.nftCA,
          nftsToSacrificeArrayUnstaked,
          sumOfSacrificeUnstaked,
          {
            gasLimit: gasLimit2Use,
            gasPrice: window.minGasCostE0,
          }
        );
        await tx.wait();
      } else if (nftsToSacrificeArrayUnstaked.length <= 0) {
        console.log("Sacrificing only staked");
        let tx = await that.contract["graveyard"].sacrificeStaked(
          that.currColl.nftCA,
          nftsToSacrificeArrayStaked,
          sumOfSacrificeStaked,
          {
            gasLimit: gasLimit2Use,
            gasPrice: window.minGasCostE0,
          }
        );
        await tx.wait();
      } else {
        console.log("Sacrificing staked and unstaked");
        let tx = await that.contract["graveyard"].sacrificeStakedAndUnstaked(
          that.currColl.nftCA,
          nftsToSacrificeArrayStaked,
          sumOfSacrificeStaked,
          nftsToSacrificeArrayUnstaked,
          sumOfSacrificeUnstaked,
          {
            gasLimit: Math.min(gasLimit2Use, 9999999),
            gasPrice: window.minGasCostE0,
          }
        );
        await tx.wait();
      }

      this.clearSelection();
      this.$emit("refreshEvent");
    },

    upgradeSelectedNfts: async function () {
      let that = this;

      // checking EXP token
      if (
        that.expCostToBulkUpgrade >
        that.stakersAllowanceOfFor[("expToken", "leveler")]
      ) {
        that.showPopup(
          "Please increase your EXP token allowance first",
          "",
          3000
        );
        return false;
      } else if (
        that.expCostToBulkUpgrade > that.stakersBalanceE0["expToken"]
      ) {
        that.showPopup("EXP token balance is too low", "", 3000);
        return false;
      }

      if (that.selectedNfts.length < 1) {
        that.showPopup("No NFTs selected", "", 3000);
        return false;
      } else if (that.selectedNfts.length == 1) {
        let _tokenId = that.selectedNfts[0];
        that.upgradeSpecificNft(
          _tokenId,
          that.nftInfo[_tokenId].expTokensForUpgrade
        );
        return false;
      } else if (that.selectedNfts.length > 1) {
        // go through selected NFTs and pick first maxNftsPerTx which qualify for an upgrade
        let nftsToUpgradeArray = [];
        let expTokensForUpgradeArray = [];

        that.showPopup(
          "Double checking ownership and level of selected NFTs",
          "",
          3000
        );

        let _copySelectedNfts = this.selectedNfts.slice();

        let gasLimit2Use = 0;
        let _feedback = "";
        for (let _tokenId of _copySelectedNfts) {
          // check cost
          if (that.nftInfo[_tokenId].expTokensForUpgrade === 0) {
            _feedback += " -- Skipping " + _tokenId + " (0 EXP upgrade)";
            removeItemOnce(this.selectedNfts, _tokenId);
            continue;
          }

          if (nftsToUpgradeArray.length >= that.maxNftsPerTx) {
            removeItemOnce(this.selectedNfts, _tokenId);
            continue;
          }

          if (that.nftInfo[_tokenId].level >= that.maxLevel) {
            _feedback += " -- Skipping " + _tokenId + " (already at max level)";
            removeItemOnce(this.selectedNfts, _tokenId);
            continue;
          }

          if (
            that.currColl.name === "kimonOld" &&
            that.nftInfo[_tokenId].staked === false
          ) {
            _feedback +=
              " -- Skipping " +
              _tokenId +
              " (old Kimon contract and not staked)";
            removeItemOnce(this.selectedNfts, _tokenId);
            continue;
          }

          // // check owner
          // let _nftOwner = await this.contract["nft"].ownerOf(_tokenId);
          // if (_nftOwner !== that.walletAddress) {
          //   if (_nftOwner !== window.address["staking"]) {
          //     _feedback+=
          //       " -- Skipping " + _tokenId + " (not in your wallet)";
          //
          //     removeItemOnce(this.selectedNfts, _tokenId);
          //     continue;
          //   }
          // }

          nftsToUpgradeArray.push(_tokenId);
          expTokensForUpgradeArray.push(
            that.nftInfo[_tokenId].expTokensForUpgrade
          );
          console.log("Adding " + _tokenId);
          console.log(nftsToUpgradeArray);

          gasLimit2Use +=
            that.nftInfo[_tokenId].nftLevelAfterUpgrade -
              that.nftInfo[_tokenId].level <
            2
              ? 330000
              : 350000;
        }

        _feedback +=
          " -- " + nftsToUpgradeArray.length + " NFTs passed all checks";
        console.log(nftsToUpgradeArray);
        console.log(expTokensForUpgradeArray);
        console.log("Bulk Upgrade Cost= " + that.expCostToBulkUpgrade);

        console.log("Balance old=" + that.stakersBalanceE0["expToken"]);
        let _expBalanceAfterUpgrade =
          that.stakersBalanceE0["expToken"] - that.expCostToBulkUpgrade;
        console.log("Balance after bulk upgrade: ", _expBalanceAfterUpgrade);

        if (nftsToUpgradeArray.length <= 0) {
          return false;
        }

        _feedback +=
          " -- Upgrading NFTs [" +
          nftsToUpgradeArray +
          "] with [" +
          expTokensForUpgradeArray +
          "] EXP";

        await this.handleTx(
          _feedback,
          this.contract["leveler"].bulkUpgradeNfts(
            that.currColl.nftCA,
            nftsToUpgradeArray,
            expTokensForUpgradeArray,
            {
              gasLimit: Math.min(gasLimit2Use, 9999999),
              gasPrice: window.minGasCostE0,
            }
          )
        );

        this.clearSelection();
        this.$emit("refreshEvent");
      }
    },

    async getPowerForNfts(_nftArr, _updateNftInfo) {
      const _leveler = this.contract["leveler"];
      const _powerArr = await _leveler["getPower(address,uint16[])"](
        this.currColl.nftCA,
        _nftArr
      );
      if (!_updateNftInfo) {
        return _powerArr;
      }

      for (let i = 0; i < _nftArr.length; ++i) {
        const _tokenId = _nftArr[i];
        this.nftInfo[(_tokenId, "power")] = _powerArr[i];
      }
    },

    partialUpgrade(_expTokens, _tokenId, oldPower, _level) {
      let expTokensConsumed = 0; // returns this
      let newPower = oldPower; // returns this

      if (_level >= this.maxLevel) {
        // no upgrade if already at max level
        console.log("Partial upgrade not possible -- already at max level.");
        return { newPower, _level, expTokensConsumed };
      }

      let fullPowerInLevel = ((oldPower - this.getMinPower(_level)) * 10) / 7;
      let fullLevelPowerIncrease =
        this.getMinPower(_level + 1) - this.getMinPower(_level);
      let powerIncreasePerExp = Math.floor(
        ((fullLevelPowerIncrease / this.expFor1LvlUpgrdTo[_level + 1]) *
          this.discountExpTokensX1k) /
          1000
      );

      while (
        expTokensConsumed < _expTokens &&
        fullPowerInLevel < fullLevelPowerIncrease
      ) {
        fullPowerInLevel += powerIncreasePerExp;
        expTokensConsumed++;
      }

      if (fullPowerInLevel >= fullLevelPowerIncrease) {
        // we reached the next level
        _level++;
        fullPowerInLevel -= fullLevelPowerIncrease;
      }
      newPower = this.getMinPower(_level) + (fullPowerInLevel * 7) / 10;

      console.log(
        "Ideal partial upgrade used " +
          expTokensConsumed +
          " EXP tokens, resulting power: " +
          Math.floor(newPower) / 10000
      );
      return { newPower, _level, expTokensConsumed };
    },

    fullLevelUpgrade(_expTokens, _tokenId, oldPower, _level) {
      let expTokensConsumed = 0; // returns this
      let newPower = oldPower; // returns this

      let expTokensPerLevel = Math.floor(
        (this.expFor1LvlUpgrdTo[_level + 1] * this.discountExpTokensX1k) / 1000
      );

      while (
        expTokensConsumed + expTokensPerLevel <= _expTokens &&
        _level < this.maxLevel
      ) {
        expTokensConsumed += expTokensPerLevel;
        let fullLevelPowerIncrease =
          this.getMinPower(_level + 1) - this.getMinPower(_level);
        newPower += fullLevelPowerIncrease;
        _level++;
        expTokensPerLevel = Math.floor(
          (this.expFor1LvlUpgrdTo[_level + 1] * this.discountExpTokensX1k) /
            1000
        );
      }

      console.log(
        "Ideal full upgrade used " +
          expTokensConsumed +
          " EXP tokens, resulting power: " +
          Math.floor(newPower) / 10000
      );
      console.log(
        "minpower: ",
        this.getMinPower(_level),
        this.getMinPower(_level + 1)
      );
      return { newPower, _level, expTokensConsumed };
    },

    calculatePowerIncreaseIdeal: function (_expTokens, _tokenId) {
      // partial upgrade first
      let startLevel = this.nftInfo[_tokenId].level;
      let startPower = this.getPowerVue(_tokenId);
      let startExpTokens = _expTokens;

      console.log(
        "Start of ideal upgrade: level " +
          startLevel +
          ", power " +
          startPower / 10000
      );

      let result1 = this.partialUpgrade(
        _expTokens,
        _tokenId,
        startPower,
        startLevel
      );
      // console.log(result1);
      _expTokens -= result1.expTokensConsumed;
      console.log(result1);

      if (result1.expTokensConsumed <= 0) {
        // if initial power was >= 70% to next level
        let result1again = this.partialUpgrade(
          _expTokens,
          _tokenId,
          result1.newPower,
          result1._level
        );
        // console.log(result1);
        _expTokens -= result1again.expTokensConsumed;
        console.log(result1again);
        result1 = result1again;
      }

      let result2 = this.fullLevelUpgrade(
        _expTokens,
        _tokenId,
        result1.newPower,
        result1._level
      );
      // console.log(result2);
      _expTokens -= result2.expTokensConsumed;
      console.log(result2);

      let result3 = this.partialUpgrade(
        _expTokens,
        _tokenId,
        result2.newPower,
        result2._level
      );
      // console.log(result3);
      _expTokens -= result3.expTokensConsumed;
      console.log(result3);

      let expTokensUsed = startExpTokens - _expTokens;
      console.log(
        expTokensUsed +
          " EXP used, " +
          _expTokens +
          " EXP left after ideal upgrade"
      );
      console.log(
        "End of ideal upgrade: Power increased from " +
          Math.floor(startPower) / 10000 +
          " (newLvl " +
          startLevel +
          ") to " +
          Math.floor(result3.newPower) / 10000 +
          " (newLvl " +
          result3._level +
          ")"
      );
    },

    upgrade1NftGasSavingsOld: function (
      _expTokens,
      _tokenId,
      startPwrX10k,
      startLvl
    ) {
      console.log("V2+ (gas savings) start");

      let that = this;
      let expTokensConsumed = 0;

      if (startLvl >= that.maxLevel) {
        // upgrade not possible, NFT already at max level
        // return instead of require for execution in loop
        // (upgrading 1 NFT for multiple levels beyond higher upgrading costs)
        expTokensConsumed = 0;
        let newPwrX10k = startPwrX10k;
        let newLvl = startLvl;
        console.log("Max level reached");
        return { expTokensConsumed, newPwrX10k, newLvl };
      }

      let minPwrX10kStartLvl = that.getMinPower(startLvl);

      ////////////////////////////
      // full level upgrades... //
      ////////////////////////////
      let newLvl = startLvl;
      let expTokensPerLevel = Math.floor(
        (this.expFor1LvlUpgrdTo[startLvl + 1] * this.discountExpTokensX1k) /
          1000
      );
      let uiIdx = 0;
      let levelWithHigherCost = that.levelToUpgradeTo[uiIdx];
      while (newLvl >= levelWithHigherCost) {
        uiIdx++;
        levelWithHigherCost = that.levelToUpgradeTo[uiIdx];
      }
      let expTokensIncPerLevel = Math.floor(
        (that.expTokensIncPerLevel[uiIdx] * this.discountExpTokensX1k) / 1000
      );

      while (
        expTokensConsumed + expTokensPerLevel <= _expTokens &&
        newLvl < that.maxLevel
      ) {
        expTokensConsumed += expTokensPerLevel;
        newLvl++;
        // NFT is in level newLvl
        if (newLvl >= levelWithHigherCost - 1) {
          // get cost for next level upgrade
          uiIdx++;
          levelWithHigherCost = that.levelToUpgradeTo[uiIdx];
          expTokensIncPerLevel = Math.floor(
            (that.expTokensIncPerLevel[uiIdx] * this.discountExpTokensX1k) /
              1000
          );
          // console.log(newLvl, uiIdx, expTokensIncPerLevel);
        }
        expTokensPerLevel += expTokensIncPerLevel;
        console.log(
          "NFT is in level",
          newLvl,
          ". Next full level upgrade costs ",
          expTokensPerLevel
        );
      }

      console.log(
        "after full level upgrades: NFT in level " +
          newLvl +
          " -- Upgrade to next level costs: " +
          expTokensPerLevel
      );

      let minPwrX10kAfterFullLvlUpgrades = that.getMinPower(newLvl);
      let newPwrX10k =
        minPwrX10kAfterFullLvlUpgrades + (startPwrX10k - minPwrX10kStartLvl); // final result if only full level upgrades

      ///////////////////////////////
      // partial level upgrades... //
      ///////////////////////////////
      if (newLvl < that.maxLevel) {
        let expTokensForPartialUpgrade =
          (_expTokens - expTokensConsumed) % expTokensPerLevel;

        if (expTokensForPartialUpgrade > 0) {
          expTokensConsumed += expTokensForPartialUpgrade;

          let minPwrX10kNextLevel = that.getMinPower(newLvl + 1);

          let pwrIncX10kWithLeftoverExp = Math.floor(
            (expTokensForPartialUpgrade *
              (minPwrX10kNextLevel - minPwrX10kAfterFullLvlUpgrades)) /
              expTokensPerLevel
          ); // linear approximation

          let newPowerFullX10k =
            minPwrX10kAfterFullLvlUpgrades +
            Math.floor(((startPwrX10k - minPwrX10kStartLvl) * 100) / 70) +
            pwrIncX10kWithLeftoverExp;
          console.log(
            "power after full upgrades: " +
              newPwrX10k +
              " (incl excess power * 10/7): " +
              newPowerFullX10k +
              " minPower: " +
              minPwrX10kAfterFullLvlUpgrades +
              " " +
              minPwrX10kNextLevel
          );

          if (newPowerFullX10k >= minPwrX10kNextLevel) {
            // excessExpTokens are sufficient to reach next level
            newLvl += 1;
            pwrIncX10kWithLeftoverExp = newPowerFullX10k - minPwrX10kNextLevel;
            newPwrX10k = minPwrX10kNextLevel;
          }
          newPwrX10k = Math.floor(
            newPwrX10k + (pwrIncX10kWithLeftoverExp * 70) / 100
          ); // take into account that partial power is not upgraded in full until next level is reached
        }
      }

      console.log(
        "after full + partial level upgrades: level: " +
          newLvl +
          " power: " +
          newPwrX10k +
          " -- expTokensPerLevel: " +
          expTokensPerLevel +
          " -- minpower: ",
        this.getMinPower(newLvl),
        this.getMinPower(newLvl + 1)
      );

      return { expTokensConsumed, newPwrX10k, newLvl };
    },

    upgrade1NftGasSavingsUngenau: function (
      _expTokens,
      _tokenId,
      startPwrX10k,
      startLvl
    ) {
      console.log("Rewritten levelling contract START");

      let that = this;
      let expTokensAtStart = _expTokens;
      let expTokensConsumed = 0;

      if (startLvl >= that.maxLevel) {
        // upgrade not possible, NFT already at max level
        // return instead of require for execution in loop
        // (upgrading 1 NFT for multiple levels beyond higher upgrading costs)
        expTokensConsumed = expTokensAtStart - _expTokens;
        let newPwrX10k = startPwrX10k;
        let newLvl = startLvl;
        console.log("Max level reached");
        return { expTokensConsumed, newPwrX10k, newLvl };
      }

      let newLvl = startLvl;

      let _minPwrStartLvlX10k = that.getMinPower(startLvl);

      ////////////////////////////
      // full level upgrades... //
      ////////////////////////////
      let exp4NextLvlUpgrd = Math.floor(
        (this.expFor1LvlUpgrdTo[startLvl + 1] * this.discountExpTokensX1k) /
          1000
      );

      let uiIdx = 0;
      let levelWithHigherCost = that.levelToUpgradeTo[uiIdx];
      while (newLvl >= levelWithHigherCost) {
        uiIdx++;
        levelWithHigherCost = that.levelToUpgradeTo[uiIdx];
      }

      let expTokensIncPerLevel = Math.floor(
        (that.expTokensIncPerLevel[uiIdx] * this.discountExpTokensX1k) / 1000
      );

      while (_expTokens >= exp4NextLvlUpgrd && newLvl < that.maxLevel) {
        ++newLvl;
        _expTokens -= exp4NextLvlUpgrd;

        // NFT is in level newLvl
        if (newLvl >= levelWithHigherCost - 1) {
          // upgrades are getting more expensive -- update variables
          ++uiIdx;

          levelWithHigherCost = that.levelToUpgradeTo[uiIdx];
          expTokensIncPerLevel = Math.floor(
            (that.expTokensIncPerLevel[uiIdx] * this.discountExpTokensX1k) /
              1000
          );
          // console.log(newLvl, uiIdx, expTokensIncPerLevel);
        }

        // cost for next upgrade
        exp4NextLvlUpgrd += expTokensIncPerLevel;
        console.log(
          "Full level upgraded: NFT is in level",
          newLvl,
          "Next full level upgrade costs",
          exp4NextLvlUpgrd
        );
      }

      console.log(
        "After full level upgrades: NFT in level",
        newLvl,
        " -- Upgrade to next level costs:",
        exp4NextLvlUpgrd
      );

      ///////////////////////////////
      // partial level upgrades... //
      ///////////////////////////////
      let _startPwrX10k = startPwrX10k;
      let _minPwrAfterFullLvlUpgrdX10k = that.getMinPower(newLvl);

      let _pwrInc = 0;
      if (_expTokens == 0 || newLvl >= that.maxLevel) {
        // no partial upgrade possible
        _pwrInc = _minPwrAfterFullLvlUpgrdX10k - _minPwrStartLvlX10k;
      } else {
        // _expTokens > 0 && v.newLvl < that.maxLevel

        let _minPwrAfterFullLvlUpgrdPlus1X10k = that.getMinPower(newLvl + 1);

        let _ptlUpgrdFullPwr = Math.floor(
          (_expTokens *
            (_minPwrAfterFullLvlUpgrdPlus1X10k -
              _minPwrAfterFullLvlUpgrdX10k)) /
            exp4NextLvlUpgrd
        ); // linear approximation

        // check if partial level upgrades jumps to next level
        if (
          Math.floor(
            _ptlUpgrdFullPwr +
              (100 * (_startPwrX10k - _minPwrStartLvlX10k)) / 70
          ) >=
          _minPwrAfterFullLvlUpgrdPlus1X10k - _minPwrAfterFullLvlUpgrdX10k
        ) {
          // jump to next level

          ++newLvl;

          console.log("Partial level upgrade increased level to", newLvl);

          _pwrInc =
            Math.floor((70 * _ptlUpgrdFullPwr) / 100) +
            Math.floor((3 * _minPwrAfterFullLvlUpgrdPlus1X10k) / 10) +
            Math.floor((7 * _minPwrAfterFullLvlUpgrdX10k) / 10) -
            _minPwrStartLvlX10k;
        } else {
          // don't jump to next level
          _pwrInc =
            Math.floor((70 * _ptlUpgrdFullPwr) / 100) +
            _minPwrAfterFullLvlUpgrdX10k -
            _minPwrStartLvlX10k;
        }
      }

      let newPwrX10k = _startPwrX10k + _pwrInc;

      _expTokens = 0; // otherwise the consumed EXP tokens are not correct

      console.log(
        "after full + partial level upgrades: level:",
        newLvl,
        "power:",
        newPwrX10k,
        "exp4NextLvlUpgrd:",
        exp4NextLvlUpgrd,
        "minpower(newLvl), (newLvl+1):",
        this.getMinPower(newLvl),
        this.getMinPower(newLvl + 1)
      );

      expTokensConsumed = expTokensAtStart - _expTokens;

      return { expTokensConsumed, newPwrX10k, newLvl };
    },

    upgrade1NftGasSavings: function (
      _expTokens,
      _tokenId,
      startPwrX10k,
      startLvl
    ) {
      // this is the version that was developed for Dogechain
      const _verbose = false;
      if (_verbose) {
        console.log("Rewritten levelling contract START");
        console.log("_expTokens", _expTokens);
        console.log("startPwrX10k", startPwrX10k);
        console.log("startLvl", startLvl);
      }

      let that = this;
      let expTokensAtStart = _expTokens;

      if (startLvl >= that.maxLevel) {
        if (_verbose) {
          console.log("Start level >= max level");
          console.log("upgrade not possible");
        }
        // return instead of require for execution in loop
        // (upgrading 1 NFT for multiple levels beyond higher upgrading costs)

        let expTokensConsumed = 0;
        let newPwrX10k = startPwrX10k;
        let newLvl = startLvl;
        return { expTokensConsumed, newPwrX10k, newLvl };
      }

      // upgrade is possible

      let newLvl = startLvl;

      let _minPwrStartLvlX10k = that.getMinPower(startLvl);
      let _startPwrX10k = startPwrX10k;

      let exp4NextLvlUpgrd = Math.floor(
        (that.expFor1LvlUpgrdTo[newLvl + 1] * that.discountExpTokensX1k) / 1000
      );
      if (_verbose) {
        console.log("exp4NextLvlUpgrd", exp4NextLvlUpgrd);
      }

      // convert power within level to extra EXP tokens
      _expTokens += Math.floor(
        ((_startPwrX10k - _minPwrStartLvlX10k) * 100 * exp4NextLvlUpgrd) /
          ((that.getMinPower(startLvl + 1) - _minPwrStartLvlX10k) * 70)
      );
      if (_verbose) {
        console.log("_expTokens", _expTokens);
        console.log("_startPwrX10k", _startPwrX10k);
        console.log("_minPwrStartLvlX10k", _minPwrStartLvlX10k);
        console.log(
          "that.getMinPower(startLvl + 1)",
          that.getMinPower(startLvl + 1)
        );
      }

      ////////////////////////////
      // full level upgrades... //
      ////////////////////////////
      let uiIdx = 0;
      // BEGIN function getUpgradeInfoIndex(uint8 _level) public view returns (uint8)
      const lastIdx = that.levelToUpgradeTo.length - 1;
      if (newLvl >= that.levelToUpgradeTo[lastIdx]) {
        uiIdx = lastIdx;
      }

      while (newLvl >= that.levelToUpgradeTo[uiIdx]) {
        uiIdx++;
      }
      // END function getUpgradeInfoIndex(uint8 _level) public view returns (uint8)

      let levelWithHigherCost = that.levelToUpgradeTo[uiIdx];
      let expTokensIncPerLevel = Math.floor(
        (that.expTokensIncPerLevel[uiIdx] * that.discountExpTokensX1k) / 1000
      );
      if (_verbose) {
        console.log("expTokensIncPerLevel", expTokensIncPerLevel);
      }

      while (_expTokens >= exp4NextLvlUpgrd && newLvl < that.maxLevel) {
        ++newLvl;
        _expTokens -= exp4NextLvlUpgrd;

        // NFT is in level newLvl
        if (newLvl >= levelWithHigherCost - 1) {
          // upgrades are getting more expensive -- update variables
          ++uiIdx;

          levelWithHigherCost = that.levelToUpgradeTo[uiIdx];
          expTokensIncPerLevel = Math.floor(
            (that.expTokensIncPerLevel[uiIdx] * that.discountExpTokensX1k) /
              1000
          );
          if (_verbose) {
            console.log(
              "NFT in level",
              newLvl,
              "expTokensIncPerLevel",
              expTokensIncPerLevel
            );
          }
        }

        // cost for next upgrade
        exp4NextLvlUpgrd += expTokensIncPerLevel;
        if (_verbose) {
          console.log(
            "Full level upgraded: NFT is in level",
            newLvl,
            "Next full level upgrade costs",
            exp4NextLvlUpgrd
          );
        }
      }

      // now _expTokens are not enough for next full level upgrade
      // => check if we can continue with partial level upgrades

      if (_verbose) {
        console.log(
          "After full level upgrades: NFT in level",
          newLvl,
          " -- Upgrade to next level costs:",
          exp4NextLvlUpgrd
        );
      }

      ///////////////////////////////
      // partial level upgrades... //
      ///////////////////////////////
      ///////////////////
      // Readable code (may not be up to date) //
      ///////////////////
      // let _minPwrAfterFullLvlUpgrdX10k = that.getMinPower(newLvl);

      // let newPwrX10k = _minPwrAfterFullLvlUpgrdX10k; // final result if partial upgrade is not possible
      // if (_expTokens > 0 && newLvl < that.maxLevel) {
      //   let _minPwrAfterFullLvlUpgrdPlus1X10k = that.getMinPower(newLvl + 1);

      //   let _ptlUpgrd = Math.floor(
      //     (_expTokens *
      //       (_minPwrAfterFullLvlUpgrdPlus1X10k - _minPwrAfterFullLvlUpgrdX10k) *
      //       70) /
      //       (100 * exp4NextLvlUpgrd)
      //   ); // linear approximation

      //   newPwrX10k += _ptlUpgrd;
      // }

      ///////////////////
      // Optimised code //
      ///////////////////
      let _pwrAfterUpgrade = that.getMinPower(newLvl); // final result if partial upgrade is not possible
      if (_expTokens > 0 && newLvl < that.maxLevel) {
        _pwrAfterUpgrade += Math.floor(
          (_expTokens *
            70 *
            (that.getMinPower(newLvl + 1) - _pwrAfterUpgrade)) /
            (100 * exp4NextLvlUpgrd)
        ); // linear approximation
      }

      _expTokens = 0; // otherwise the consumed EXP tokens are not correct -- only needed for website, not for contract

      if (_verbose) {
        console.log(
          "after full + partial level upgrades: level:",
          newLvl,
          "power:",
          _pwrAfterUpgrade,
          "exp4NextLvlUpgrd:",
          exp4NextLvlUpgrd,
          "minpower(newLvl), (newLvl+1):",
          that.getMinPower(newLvl),
          that.getMinPower(newLvl + 1)
        );
      }

      let expTokensConsumed = expTokensAtStart - _expTokens;

      return { expTokensConsumed, newPwrX10k: _pwrAfterUpgrade, newLvl };
    },

    calculatePowerIncreaseSolidityV2: function (_expTokens, _tokenId) {
      let startLvl = this.nftInfo[_tokenId].level;
      let startPwrX10k = this.getPowerVue(_tokenId);

      let result2 = this.upgrade1NftGasSavings(
        _expTokens,
        _tokenId,
        startPwrX10k,
        startLvl
      );

      // update nft info
      this.nftInfo[_tokenId].nftPowerAfterUpgrade = result2.newPwrX10k;
      this.nftInfo[_tokenId].nftLevelAfterUpgrade = result2.newLvl;
      this.nftInfo[_tokenId].expTokensForUpgrade = result2.expTokensConsumed;
      this.nftInfo[_tokenId].pctInLevelX100AfterUpgrade = this.getPctX100InLvl(
        result2.newPwrX10k,
        result2.newLvl
      );
    },

    getPctX100InLvl: function (_pwrX10k, _level) {
      let _minpwrLevel = this.getMinPower(_level);
      let _minpwrNextLevel = this.getMinPower(_level + 1);
      return Math.min(
        10000,
        Math.round(
          (((_pwrX10k - _minpwrLevel) / (_minpwrNextLevel - _minpwrLevel)) *
            10000) /
            0.7
        )
      );
    },

    getLevel: function (_nftPower) {
      let levelMinPower;
      let _level = 0;
      do {
        ++_level;

        levelMinPower = this.getMinPower(_level);
      } while (levelMinPower <= _nftPower);
      return _level - 1;
    },

    sortNfts: function (_param) {
      let that = this;
      that.sorting = _param;
      switch (_param) {
        case "Number ascending":
          that.stakersNfts.sort(function (a, b) {
            return a - b;
          });
          break;
        case "Number descending":
          that.stakersNfts.sort(function (a, b) {
            return b - a;
          });
          break;
        case "Power":
          that.stakersNfts.sort(function (a, b) {
            return (
              that.nftInfo[b].staked - that.nftInfo[a].staked ||
              that.nftInfo[b].power - that.nftInfo[a].power ||
              a - b
            );
          });
          break;
        case "EXP needed to reach next level":
          that.stakersNfts.sort(function (a, b) {
            return (
              that.nftInfo[b].staked - that.nftInfo[a].staked ||
              that.nftInfo[a].expTokensUntilNextLevel -
                that.nftInfo[b].expTokensUntilNextLevel ||
              a - b
            );
          });
          break;
        case "% in level":
          that.stakersNfts.sort(function (a, b) {
            return (
              that.nftInfo[b].staked - that.nftInfo[a].staked ||
              that.nftInfo[b].pctInLevelX100 - that.nftInfo[a].pctInLevelX100 ||
              a - b
            );
          });
          break;
        default:
          that.showPopup("Sorting not implemented", "", 3000);
      }
    },

    filterNfts: function (_param) {
      let that = this;
      that.filter = _param;
      switch (_param) {
        case "all":
          that.filteredNfts = that.stakersNfts;
          break;
        case "staked":
          that.filteredNfts = that.stakersNfts.filter((_tokenId) => {
            return that.nftInfo[_tokenId].staked === true;
          });
          break;
        case "unstaked":
          that.filteredNfts = that.stakersNfts.filter((_tokenId) => {
            return that.nftInfo[_tokenId].staked === false;
          });
          break;
        default:
          that.showPopup(`Filter ${_param} not implemented`, "", 3000);
      }
    },

    sortFilterShowNfts() {
      const that = this;
      console.log("sorting stakersNfts -> stakersNfts");
      that.sortNfts(that.sorting);
      console.log("filter stakersNfts -> filteredNfts");
      that.filterNfts(that.filter);
      console.log("show filteredNfts -> nftsToShow");
      that.nftsToShow = that.filteredNfts.slice(0, that.maxNftsToLoad);
    },

    fillNftInfo: async function (_inArr, _staked) {
      let that = this;

      let _powerArr = [];
      // if (that.isDogechain) {
      _powerArr = await that.getPowerForNfts(_inArr, false);
      // } else {
      //   // get power of all NFTs
      //   await that.updatePower();

      //   for (let i in _inArr) {
      //     const _tokenId = _inArr[i];
      //     _powerArr.push(that.nftPower[_tokenId - 1]);
      //   }
      // }

      for (let i in _inArr) {
        const _tokenId = _inArr[i];
        that.stakersNfts.push(_tokenId);

        let power = _powerArr[i];
        let level = that.getLevel(power);
        let sacrificePrice = that.getSacrificePrice(power, level);
        let minpwrLevel = that.getMinPower(level);
        let minpwrNextLevel = that.getMinPower(level + 1);
        let pctInLevelX100 = Math.min(
          10000,
          Math.round(
            (((power - minpwrLevel) / (minpwrNextLevel - minpwrLevel)) *
              10000) /
              0.7
          )
        );
        let _expTokensUntilNextLevel = 0;
        if (level < that.maxLevel) {
          _expTokensUntilNextLevel = Math.max(
            1,
            Math.ceil(
              (((that.expFor1LvlUpgrdTo[level + 1] *
                that.discountExpTokensX1k) /
                1000) *
                (minpwrNextLevel - (10 / 7) * power + (3 / 7) * minpwrLevel)) /
                (minpwrNextLevel - minpwrLevel)
            )
          );
        }
        // console.log(i, _tokenId, level, uiIdx, that.expFor1LvlUpgrdTo[_level+1], pctInLevelX100);
        that.nftInfo[_tokenId] = {
          staked: _staked,
          power: power,
          level: level,
          sacrificePrice: sacrificePrice,
          minpwr: minpwrLevel,
          pctInLevelX100: pctInLevelX100,
          expTokensUntilNextLevel: _expTokensUntilNextLevel,
          expTokensForUpgrade: _expTokensUntilNextLevel,
          nftPowerAfterUpgrade: minpwrNextLevel,
          nftLevelAfterUpgrade: level + 1,
          pctInLevelX100AfterUpgrade: 0,
          image: that.currColl.pictureLink + _tokenId + ".png",
        };
      }

      if (_staked === true) {
        that.loadingStakedNfts = false;
      } else {
        that.loadingUnstakedNfts = false;
      }

      if (
        that.loadingStakedNfts === false &&
        that.loadingUnstakedNfts === false
      ) {
        that.showPopup("Loading", "was successful", 1000);
        that.sortFilterShowNfts();
      }
    },

    getBalance(_token) {
      const that = this;
      that.contract[_token].balanceOf(that.walletAddress).then((_balance) => {
        that.stakersBalanceE0[_token] = _balance;
      });
    },

    getAllowanceOfFor(_token, _contract) {
      const that = this;
      that.contract[_token]
        .allowance(that.walletAddress, window.address[_contract])
        .then((_allowance) => {
          that.stakersAllowanceOfFor[(_token, _contract)] = _allowance;
        });
    },

    load: async function () {
      const that = this;

      console.log("isDogechain", that.isDogechain);

      that.loadingUnstakedNfts = true;
      that.loadingStakedNfts = true;

      that.filteredNfts = [];
      that.stakersNfts = [];
      that.nftsToShow = [];

      that.walletAddress = await that.signer.getAddress();

      // load approval for staking
      that.contract["nft"]
        .isApprovedForAll(that.walletAddress, window.address["staking"])
        .then((_collectionApprovedForStaking) => {
          that.collectionApprovedFor["staking"] = _collectionApprovedForStaking;

          that.showPopup("Loading", "", 0); // after loading from 1 smart contract so that it won't be shown if connected to wrong network
        });

      // load approval for graveyard
      that.contract["nft"]
        .isApprovedForAll(that.walletAddress, window.address["graveyard"])
        .then((_collectionApprovedForStaking) => {
          this.collectionApprovedFor["graveyard"] =
            _collectionApprovedForStaking;
        });

      // load graveyard parameters
      that.contract["graveyard"]
        .getCollDiscount_ExpForLvl0_SacFac(that.currColl.nftCA)
        .then((_res) => {
          that.discountExpTokensX1k = Number(_res[0]);
          that.expForLvl0 = Number(_res[1]);
          that.sacrificeFactorX1k = Number(_res[2]);
          console.log("discountExpTokensX1k", that.discountExpTokensX1k);
          console.log("expForLvl0", that.expForLvl0);
          console.log("sacrificeFactorX1k", that.sacrificeFactorX1k);
        });

      that.getBalance("expToken");

      that.getAllowanceOfFor("expToken", "leveler");

      // load EXP price in NFTC
      that.contract["expSeller"]
        .getExpPriceInTokensAndEthAndPaused([window.address["nftc"]])
        .then((pricesE0) => {
          that.expTokenPrice["nftc"] = ethers.BigNumber.from(pricesE0[0]);
          that.expTokenSellerActive = pricesE0[2] === 0 ? true : false;
        });

      //////////////
      // unstaked //
      //////////////
      that.contract["nft"]
        .walletOfOwner(that.walletAddress)
        .then((_nftsInWallet) => {
          let _nftsInWalletArr = [];
          for (let iCopy = 0; iCopy < _nftsInWallet.length; iCopy++) {
            _nftsInWalletArr.push(Number(_nftsInWallet[iCopy]));
          }
          that.unstakedCount = _nftsInWalletArr.length;
          that.fillNftInfo(_nftsInWalletArr, false);
        });

      //////////////////////
      // staked new start //
      //////////////////////
      if (this.isDogechain === true) {
        let _myStakedNfts = [];
        const _step = 400;
        const _totalNfts = 10000;
        let _reqsFinished = 0;
        const _reqsToFinish = _totalNfts / _step;
        const promises = [];

        for (let _from = 1; _from < _totalNfts; _from += _step) {
          const _to = Math.min(_totalNfts, _from + _step);
          console.log(_from, _to);

          promises.push(
            new Promise((resolve) => {
              try {
                that.contract["staking"]
                  .getStakedNfts(
                    that.walletAddress,
                    that.currColl.nftCA,
                    _from,
                    _to
                  )
                  .then((_resArr) => {
                    let _breaks = 0;
                    for (
                      let _iNumber = 0;
                      _iNumber < _resArr.length;
                      ++_iNumber
                    ) {
                      let _bigNr = ethers.BigNumber.from(_resArr[_iNumber]);
                      console.log(_bigNr.toString());

                      for (let _iExp = 0; _iExp < 256; ++_iExp) {
                        const _bigNrMod2 = _bigNr.mod(2);
                        if (_bigNrMod2.eq(1)) {
                          const _nftNr = _iExp + 256 * _iNumber + _from;
                          _myStakedNfts.push(_nftNr);

                          console.log(
                            _nftNr,
                            _bigNr.toString(),
                            _bigNrMod2.toString(),
                            _from,
                            _iNumber
                          );
                          _bigNr = _bigNr.sub(1);
                        }

                        _bigNr = _bigNr.div(2);
                        if (_bigNr.eq(0)) {
                          console.log(
                            _from,
                            _iNumber,
                            "breaks out of for loop"
                          );
                          ++_breaks;
                          break; // end for loop
                        }
                      }
                    }

                    if (_breaks >= _resArr.length) {
                      console.log(_from, "promise is finished");
                      ++_reqsFinished;
                      const _percentage = Math.round(
                        (_reqsFinished / _reqsToFinish) * 100
                      );
                      this.showPopup(
                        `Loading ${_percentage}%`,
                        "This may take a while",
                        0
                      );
                      resolve(); // promise is okay
                    }
                  });
              } catch {
                this.showPopup(
                  `Loading failed`,
                  "Please try again (possibly using a different rpc)",
                  0
                );
              }
            })
          );
        }

        Promise.all(promises).then(() => {
          that.stakedCount = _myStakedNfts.length;
          that.fillNftInfo(_myStakedNfts, true);
        });
      }

      ////////////////////
      // staked new end //
      ////////////////////
      else {
        //////////////////////
        // staked old start //
        //////////////////////
        // copying staked Nfts from snapshot
        let _myStakedNfts = [];
        if (that.walletAddress in that.currColl.initialStakes) {
          _myStakedNfts = await that.currColl.initialStakes[
            that.walletAddress
          ].slice();
        }
        // loading stakes and unstakes that came later
        // console.log("nextBlock: " + that.currColl.initialStakes.nextBlockToCheck);
        const optionsStake = {
          // filter: { to: [ this.contract["staking"].contractAddress ] },
          fromBlock: that.currColl.initialStakes.nextBlockToCheck, //Number || "earliest" || "pending" || "latest"
          toBlock: "latest",
        };
        try {
          let web3HttpMint = new Web3(window.rpc);
          const mintContractHttp = new web3HttpMint.eth.Contract(
            SimpleNFT.abi,
            that.currColl.nftCA
          );
          // console.log("Getting past events for staking / unstaking");
          mintContractHttp
            .getPastEvents("Transfer", optionsStake)
            .then(function (events) {
              if (0 >= events.length) {
                console.log("No events");
                return true;
              }
              // that.currColl.initialStakes.nextBlockToCheck = Math.max(that.currColl.initialStakes.nextBlockToCheck, (events[events.length - 1].blockNumber + 1));
              console.log(events.length + " events for staking/unstaking");
              for (let item of events) {
                // console.log(item.returnValues);
                let id = parseInt(item.returnValues.tokenId);
                let from = item.returnValues.from;
                let to = item.returnValues.to;
                ///////////////////////////////////////////
                // check for converted kimonOld to kimon //
                ///////////////////////////////////////////
                if (that.currColl.name === "kimon") {
                  if (from === "0x0000000000000000000000000000000000000000") {
                    if (_myStakedNfts.includes(id)) {
                      console.log(id, "was converted from old to new by", to);
                      removeItemOnce(_myStakedNfts, id);
                    }
                  }
                }
                // staking
                if (
                  from == that.walletAddress &&
                  to == window.address["staking"]
                ) {
                  if (_myStakedNfts.includes(id)) {
                    console.log(
                      "Trying to stake an NFT that is already staked!"
                    );
                  } else {
                    _myStakedNfts.push(id);
                    // console.log(_myStakedNfts);
                  }
                  // console.log(item.blockNumber + ": " + from + " staked " + id);
                }
                // unstaking
                if (
                  to == that.walletAddress &&
                  from == window.address["staking"]
                ) {
                  removeItemOnce(_myStakedNfts, id);
                  // console.log(_myStakedNfts);
                  // console.log(item.blockNumber + ": " + to + " unstaked " + id);
                }
                if (window.netId === 10001) {
                  // SmartBCH Testnet
                  const oldGraveyards = [
                    "0xCA2b8E9cb80d6D3e79ff03d6C7050F8D3df25a72",
                    "0x4513Ff6516AFaf9B7BD79B01BaB562ED80367faB",
                  ];
                  for (const oldGraveyard of oldGraveyards) {
                    // sacrifice to old graveyards
                    if (
                      from == window.address["staking"] &&
                      to == oldGraveyard
                    ) {
                      removeItemOnce(_myStakedNfts, id);
                      // console.log(_myStakedNfts);
                      console.log(
                        item.blockNumber +
                          ": " +
                          id +
                          " sacrificed to old graveyard"
                      );
                    }
                  }
                  // SmartBCH Testnet
                }
                // sacrifice to current graveyard
                if (
                  from == window.address["staking"] &&
                  to == window.address["graveyardNftHolder"]
                ) {
                  removeItemOnce(_myStakedNfts, id);
                  // console.log(_myStakedNfts);
                  console.log(
                    item.blockNumber +
                      ": " +
                      id +
                      " sacrificed to current graveyard NFT Holder"
                  );
                }
              }
            })
            .then(() => {
              that.stakedCount = _myStakedNfts.length;
              that.fillNftInfo(_myStakedNfts, true);
            });
          // .catch(function (error) {
          //   console.log(error);
          // });
        } catch (e) {
          console.log(e);
        }
        /////////////////////
        // old version end //
        /////////////////////
      }
    },
  },

  mounted: function () {
    this.rpc = window.ethereum;
    console.log(this.rpc);
    // rpc is not just the https, it is a more complicated object

    // This is wrong!
    // if (this.currColl.name === "kimon") {
    //   this.rpc = window.rpc;
    //   // use Dogmoney for everything
    // }

    this.provider = new ethers.providers.Web3Provider(this.rpc, "any");

    this.provider
      .send("eth_requestAccounts", [])
      .then(() => {
        this.signer = this.provider.getSigner();

        this.contract["nft"] = new ethers.Contract(
          this.currColl.nftCA,
          SimpleNFT.abi,
          this.provider.getSigner()
        );

        this.contract["nftc"] = new ethers.Contract(
          window.address["nftc"],
          SimpleToken.abi,
          this.provider.getSigner()
        );

        this.contract["power"] = new ethers.Contract(
          window.address["power"],
          NftPower.abi,
          this.provider.getSigner()
        );

        if (this.isDogechain) {
          this.contract["staking"] = new ethers.Contract(
            window.address["staking"],
            NftStaking.abi,
            this.provider.getSigner()
          );
        } else {
          this.contract["staking"] = new ethers.Contract(
            window.address["staking"],
            NftStake.abi,
            this.provider.getSigner()
          );
        }

        this.contract["leveler"] = new ethers.Contract(
          window.address["leveler"],
          NftLeveler.abi,
          this.provider.getSigner()
        );

        this.contract["expToken"] = new ethers.Contract(
          window.address["expToken"],
          ExpToken.abi,
          this.provider.getSigner()
        );

        this.contract["expSeller"] = new ethers.Contract(
          window.address["expSeller"],
          ExpTokenSeller.abi,
          this.provider.getSigner()
        );

        this.contract["graveyard"] = new ethers.Contract(
          window.address["graveyard"],
          Graveyard.abi,
          this.provider.getSigner()
        );
      })
      .then(() => {
        this.load();
      });
  },
};
</script>


<style scoped>
/* checkbox {
  accent-color: rgb(27, 222, 46);
}
progress {
  accent-color: rgb(27, 222, 46);
}
input {
  accent-color: currentColor;
} */

.dropdown-item {
  color: #ffffff;
  background-color: #0075ff;
}

.dropdown-item:hover {
  cursor: pointer;
}

.navbar {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

@media (max-width: 1100px) {
  .settings-menu h4 {
    font-size: 1rem;
  }
  .btn {
    font-size: 0.75rem;
  }
}

@media (max-width: 1010px) {
  .settings-menu h4 {
    font-size: 0.88rem;
  }
  .btn {
    padding: 0.36rem 0.725rem;
    font-size: 0.7rem;
  }
}

@media (max-width: 630px) {
  .settings-menu h7 {
    font-size: 0.7rem;
  }
  .btn {
    padding: 0.35rem 0.7rem;
    font-size: 0.6rem;
  }
}
</style> 